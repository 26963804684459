<template>
	<svg class="flag-container" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" :width="size" height="16px"
		viewBox="0 0 200 200" xml:space="preserve">
		<g>
			<g>
				<g>
					<g>
						<defs>
							<circle id="SVGID_1_" cx="100.5" cy="99.5" r="99.5"/>
						</defs>
						<clipPath id="SVGID_2_">
							<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
						</clipPath>
						<g class="st0">
							<polygon class="st1" points="-51.3,0.1 252.4,0.1 252.4,199 -51.3,199"/>
							<path class="st2" d="M-51.3,184.5h303.6V199H-51.3V184.5L-51.3,184.5z M-51.3,155h303.6v14.5H-51.3V155L-51.3,155z
								M-51.3,124.1h303.6v14.5H-51.3V124.1L-51.3,124.1z M-51.3,93.2h303.7v14.5l-303.7-0.1C-51.3,107.6-51.3,93.2-51.3,93.2z
								M-51.4,62.2h303.7v14.5H-51.4V62.2L-51.4,62.2z M-51.4,31.3h303.7v14.5H-51.4V31.3L-51.4,31.3z M-51.4,0.1h303.7V15H-51.4V0.1
								L-51.4,0.1z"/>
							<polygon class="st3" points="-51.3,0 48.6,0 48.6,107.7 -51.3,107.6"/>
							<path class="st1" d="M35.3,101.2l1.6-5.3l-4-3.4h4.9l1.5-4.9l1.5,4.9h5.1l-4,3.4l1.5,5.2l-4-3.2L35.3,101.2L35.3,101.2z
								M17.4,101.2l1.6-5.3l-4-3.4h4.9l1.5-4.9l1.4,4.9h5.1l-4,3.4l1.5,5.2l-4-3.2L17.4,101.2L17.4,101.2z M0,101.2l1.6-5.3l-4.1-3.4
								h5L4,87.6l1.5,4.9h5.1l-4,3.4L8,101l-4-3.2L0,101.2L0,101.2z M-17.9,101.2l1.6-5.3l-4-3.4h4.9l1.5-4.9l1.5,4.9h5.1l-4,3.4
								l1.5,5.2l-4-3.2L-17.9,101.2L-17.9,101.2z M-35,101.2l1.6-5.3l-4-3.4h4.9l1.5-4.9l1.4,4.9h5.1l-4.1,3.4l1.5,5.2l-4-3.2
								L-35,101.2L-35,101.2z M25.9,76.6l1.6-5.3l-4-3.3h4.9l1.5-4.9l1.4,4.9h5.1l-4,3.3l1.5,5.2l-4-3.1L25.9,76.6L25.9,76.6z M8,76.6
								l1.6-5.3l-4-3.3h5l1.4-5l1.5,4.9h5.1l-4.1,3.3l1.5,5.2l-4-3.1L8,76.6L8,76.6z M-9.4,76.6l1.6-5.3l-4-3.3h4.9l1.4-4.9L-4,68h5.1
								l-4,3.3l1.5,5.2l-4.1-3.1L-9.4,76.6L-9.4,76.6z M-27.3,76.6l1.6-5.3l-4-3.3h4.9l1.5-4.9l1.4,4.9h5.1l-4,3.3l1.5,5.2l-4-3.1
								L-27.3,76.6L-27.3,76.6z M-44.5,76.6l1.6-5.3L-47,68h5l1.5-4.9L-39,68h5.1l-4,3.3l1.5,5.2l-4-3.1L-44.5,76.6L-44.5,76.6z
								M35.3,50.3l1.6-5.3l-4-3.3h4.9l1.5-4.9l1.5,4.9h5.1l-4,3.3l1.5,5.1l-4-3.1L35.3,50.3L35.3,50.3z M17.4,50.3L19,45l-4-3.3h4.9
								l1.5-4.9l1.4,4.9h5.1l-4,3.3l1.5,5.1l-4-3.1L17.4,50.3L17.4,50.3z M0,50.3L1.6,45l-4.1-3.3h5l1.5-5l1.5,4.9h5.1l-4,3.3L8,50.1
								L4,47L0,50.3L0,50.3z M-17.9,50.3l1.6-5.3l-4-3.3h4.9l1.5-4.9l1.5,4.9h5.1l-4,3.3l1.5,5.1l-4-3.1L-17.9,50.3L-17.9,50.3z
								M-35,50.3l1.6-5.3l-4-3.3h4.9l1.5-4.9l1.4,4.9h5.1l-4.1,3.3l1.5,5.1l-4-3.1L-35,50.3L-35,50.3z M25.9,24.2l1.6-5.3l-4-3.3h4.9
								l1.5-4.9l1.4,4.9h5.1l-4,3.3l1.5,5.1l-4-3.1L25.9,24.2L25.9,24.2z M8,24.2l1.6-5.3l-4-3.3h5l1.5-4.9l1.5,4.9h5.1l-4.1,3.3
								L16,24l-4-3.1L8,24.2L8,24.2z M-9.4,24.2l1.6-5.3l-4-3.3h4.9l1.4-4.9l1.5,4.9h5.1l-4,3.3l1.5,5.1l-4.1-3.1L-9.4,24.2L-9.4,24.2
								z M-27.3,24.2l1.6-5.3l-4-3.3h4.9l1.5-4.9l1.4,4.9h5.1l-4,3.3l1.5,5.1l-4-3.1L-27.3,24.2L-27.3,24.2z M-44.5,24.2l1.6-5.3
								l-4.1-3.3h5l1.5-4.9l1.5,4.9h5.1l-4,3.3l1.5,5.1l-4-3.1L-44.5,24.2L-44.5,24.2z"/>
							<polygon class="st4" points="-51.3,0.1 252.4,0.1 252.4,199 -51.3,199 					"/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>
<script>
export default {
    name: 'iconAmericanFlag',    
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--dark-main-navy)'
        },
        enableBackground: {
        type: Boolean
        }
    }
}
</script>

<style type="text/css" scoped>
	.st0{clip-path:url(#SVGID_2_);}
	.st1{fill:#FFFFFF;}
	.st2{fill:#CF2127;}
	.st3{fill:#2E3191;}
	.st4{fill:none;}
	.flag-container {
		height: 22px;
		width: 23px;
		
		padding: 2px;
		border-radius: 50%;
	}
</style>

